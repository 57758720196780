import { types } from "../types/types";

const initialState = {
    requestId: "",
};

export const requestReducer = (state = initialState, action: { type: string; payload: string }) => {
    switch (action.type) {
        case types.setRequestId:
            return {
                ...state,
                requestId: action.payload,
            };

        default:
            return state;
    }
};
