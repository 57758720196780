import { types, NotificationState } from "../types/types";
import { Notification } from "../utils/Notification";

const initialState: NotificationState = {
    notifications: [],
};

export const notificationReducer = (state = initialState, action: { type: string; payload: Notification | string }) => {
    switch (action.type) {
        case types.notificationAdd:
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        case types.notificationDelete:
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.Id !== action.payload),
            };

        default:
            return state;
    }
};
