import { useEffect, useState } from "react";
import { ToastNotifications, ToastNotification } from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";
import { useNotification } from "../../../hooks/useNotification";
import { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION, Notification } from "../../../utils/Notification";
import { deleteNotification } from "../../../actions/notificationAction";
import { UnknownAction } from "@reduxjs/toolkit";

export const NotificationToast = () => {
    const notifications: any = useNotification();
    const dispatch = useDispatch();
    const [notificationsToast, setNotificationsToast] = useState<Notification[]>([]);

    const handleOnClose = (notification: Notification) => {
        dispatch(deleteNotification(notification) as unknown as UnknownAction);
    };

    useEffect(() => {
        setNotificationsToast(
            notifications.filter((notification: Notification) => notification.Type === TYPE_NOTIFICACION.TOAST),
        );
    }, [notifications]);

    return (
        <>
            {notificationsToast?.length > 0 && (
                <ToastNotifications>
                    {notificationsToast.map((notification) => {
                        if (notification.Severity === SEVERITY_NOTIFICACION.ERROR) {
                            return (
                                <ToastNotification
                                    key={"Notification_" + notification.Id}
                                    error
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }
                        if (notification.Severity === SEVERITY_NOTIFICACION.INFO) {
                            return (
                                <ToastNotification
                                    info
                                    key={"Notification_" + notification.Id}
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }
                        if (notification.Severity === SEVERITY_NOTIFICACION.SUCCESS) {
                            return (
                                <ToastNotification
                                    success
                                    key={"Notification_" + notification.Id}
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }
                        if (notification.Severity === SEVERITY_NOTIFICACION.WARNING) {
                            return (
                                <ToastNotification
                                    warning
                                    key={"Notification_" + notification.Id}
                                    onClose={() => handleOnClose(notification)}
                                >
                                    {notification.Description}
                                </ToastNotification>
                            );
                        }

                        return (
                            <ToastNotification
                                key={"Notification_" + notification.Id}
                                onClose={() => handleOnClose(notification)}
                            >
                                {notification.Description}
                            </ToastNotification>
                        );
                    })}
                </ToastNotifications>
            )}
        </>
    );
};
