import { combineReducers } from "redux";
import { requestReducer } from "../reducers/requestReducer";
import { configureStore } from "@reduxjs/toolkit";
import { notificationReducer } from "../reducers/notificationReducer";

const reducers = combineReducers({
    request: requestReducer,
    notification: notificationReducer,
});

export const store = configureStore({ reducer: reducers });

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
