import { useEffect, useState } from "react";
import { useNotification } from "../../../hooks/useNotification";

import { Modal } from "@vwfs-bronson/bronson-react";
import { deleteNotification } from "../../../actions/notificationAction";
import { useDispatch } from "react-redux";
import { Notification, TYPE_NOTIFICACION } from "../../../utils/Notification";
import { UnknownAction } from "@reduxjs/toolkit";

export const NotificationModal = () => {
    const notifications = useNotification() as any;
    const dispatch = useDispatch();

    const [notificationsModal, setNotificationsModal] = useState<Notification[]>([]);

    useEffect(() => {
        setNotificationsModal(
            notifications
                .filter((x: Notification) => x.Type === TYPE_NOTIFICACION.MODAL)
                .map((x: Notification) => ({ ...x, Shown: true })),
        );
    }, [notifications]);

    const handleOnClose = (notification: Notification) => {
        dispatch(deleteNotification(notification) as unknown as UnknownAction);
    };

    return (
        <>
            {notificationsModal?.length > 0 && (
                <Modal
                    center
                    buttonCloseLabel="Close this dialog window."
                    buttonConfirmLabel="Close this dialog window."
                    buttonConfirmText="Aceptar"
                    buttonConfirmType="submit"
                    id="modalId"
                    onClickOutside={function noRefCheck() {}}
                    onClose={() => handleOnClose(notificationsModal[0])}
                    onConfirm={() => handleOnClose(notificationsModal[0])}
                    shown
                    title={notificationsModal[0].Title}
                >
                    <div className="u-m">{notificationsModal[0].Description}</div>
                </Modal>
            )}
        </>
    );
};
