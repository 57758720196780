import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";

export const PrivateLayout = () => {
    const { isAuth, isLoaded, validate } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoaded && !isAuth) {
            navigate("/unauthorized", { replace: true });
        }

        if (isLoaded && isAuth) {
            navigate("/", { replace: true });
        }
    }, [isAuth, isLoaded, navigate]);

    useEffect(() => {
        if (!isLoaded) {
            validate("");
        }
    }, [validate, isLoaded]);

    return <Outlet />;
};
