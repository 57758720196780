import { types, NotificationActionTypes } from "../types/types";
import { Notification } from "../utils/Notification";
import { Dispatch } from "redux";

export const deleteNotification = (notificacion: Notification): NotificationActionTypes => {
    return {
        type: types.notificationDelete,
        payload: notificacion.Id,
    };
};

const setTimeOutForNotifications = (notification: Notification) => {
    return (dispatch: Dispatch<NotificationActionTypes>) => {
        setTimeout(() => dispatch(deleteNotification(notification)), notification.Timeout ?? undefined);
    };
};

export const addNotification = (notification: Notification) => {
    return (dispatch: Dispatch<NotificationActionTypes>) => {
        dispatch({
            type: types.notificationAdd,
            payload: notification,
        });

        if (notification.Timeout)
            dispatch(<NotificationActionTypes>(<unknown>setTimeOutForNotifications(notification)));
    };
};
