import { lazy, Suspense } from "react";

const AUDI = lazy(() => import("./audi"));
const BLUELABEL = lazy(() => import("./bluelabel"));
const CUPRA = lazy(() => import("./cupra"));
const SEAT = lazy(() => import("./seat"));
const SKODA = lazy(() => import("./skoda"));
const VW6 = lazy(() => import("./vw6"));

interface Props {
    children: React.ReactElement;
}

export const ThemeSelector = ({ children }: Props) => {
    return (
        <>
            <Suspense fallback={<></>}>
                {import.meta.env.VITE_REACT_APP_BRONSON_BRAND === "audi" && <AUDI>{children}</AUDI>}
                {import.meta.env.VITE_REACT_APP_BRONSON_BRAND === "bluelabel" && <BLUELABEL>{children}</BLUELABEL>}
                {import.meta.env.VITE_REACT_APP_BRONSON_BRAND === "cupra" && <CUPRA>{children}</CUPRA>}
                {(import.meta.env.VITE_REACT_APP_BRONSON_BRAND === "lcv" ||
                    import.meta.env.VITE_REACT_APP_BRONSON_BRAND === "vw") && <VW6>{children}</VW6>}
                {import.meta.env.VITE_REACT_APP_BRONSON_BRAND === "seat" && <SEAT>{children}</SEAT>}
                {import.meta.env.VITE_REACT_APP_BRONSON_BRAND === "skoda" && <SKODA>{children}</SKODA>}
            </Suspense>
        </>
    );
};
