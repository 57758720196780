import { useEffect, useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "../services";
import { validateUUIDv4 } from "../utils";

interface Context {
    isAuth: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    validate: (uuid: string) => void;
}

export const AuthContext = createContext({} as Context);

interface Props {
    children: React.ReactElement;
}

export const AuthProvider = ({ children }: Props) => {
    const [isAuth, setIsAuth] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const validate = (uuid: string) => {
        if (!uuid) {
            LocalStorageService.getItem() ? setIsAuth(true) : setIsAuth(false);
            setIsLoaded(true);
            return;
        }

        if (validateUUIDv4(uuid)) {
            LocalStorageService.setItem(uuid);
            setIsAuth(true);
            return;
        }

        setIsAuth(false);
        setIsLoaded(true);
    };

    useEffect(() => {
        if (isAuth) setIsLoaded(true);
    }, [isAuth]);

    useEffect(() => {
        if (isLoaded) {
            setIsLoading(false);
            if (!isAuth) navigate("/unauthorized");
            else navigate("/");
        }
    }, [isLoaded, navigate, isAuth]);

    const value = {
        isAuth,
        isLoaded,
        isLoading,
        validate,
    } as Context;

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
