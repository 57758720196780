import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { LocalStorageService } from "../services";

export class AxiosClient {
    private axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: import.meta.env.VITE_REACT_APP_API_URL,
            headers: {
                "x-api-key": import.meta.env.VITE_REACT_APP_API_KEY,
            },
        });

        // Interceptor para las solicitudes
        this.axiosInstance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                const token = LocalStorageService.getItem();

                if (!token) {
                    throw new Error("Unauthorize");
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        // Interceptor para las respuestas
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => {
                //TODO: return adapterApiGatewayProxyResponseToObject(response);
                return response;
            },
            (error) => {
                // Manejar errores en la respuesta
                return Promise.reject(error);
            },
        );
    }

    // Métodos de la API que utilizan el cliente Axios
    async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.get<T>(url, config);
        return response.data;
    }

    async post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.post<T>(url, data, config);
        return response.data;
    }
}
