import { Spinner } from "@vwfs-bronson/bronson-react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { LocalStorageService } from "../services/LocalStorageService";
import { useAuth } from "../hooks";
import { setRequestId } from "../actions/requestAction";
import { useDispatch } from "react-redux";

export const AuthPage = () => {
    const { token } = useParams();
    const { validate } = useAuth();
    const dispatch = useDispatch();

    dispatch(setRequestId(token));

    useEffect(() => {
        LocalStorageService.removeItem();

        if (token) {
            validate(token);
        }
    }, [validate, token]);

    return <Spinner fullPage />;
};
