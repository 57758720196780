import { AxiosError } from "axios";
import { AxiosClient } from "../config/axiosClient";
import { FSOVCoreInterface } from "../types/types";

const api = new AxiosClient();

export const FSOVCoreConfiguration = async (calculatorType: string, requestId: string) => {
    try {
        const FSOVCoreResponse: FSOVCoreInterface = await api.post(`configuration/${requestId}`, {
            calculatorType,
        });
        const { errors } = FSOVCoreResponse ?? [];

        if (errors.length === 0) {
            window.location.href = FSOVCoreResponse.header.returnUrl;
        }

        return FSOVCoreResponse;
    } catch (error) {
        if (error instanceof AxiosError) {
            const { message, status } = error as AxiosError;

            console.log(message);
            console.log(status);
            console.log(error);
        } else {
            console.log(error);
        }
    }
};

export const HasLeasingAccess = async (requestId: string) => {
    try {
        const res = await api.get<boolean>(`configuration/${requestId}/leasing-valid`);
        return res;
    } catch (error) {
        if (error instanceof AxiosError) {
            const { message, status } = error as AxiosError;

            console.log(message);
            console.log(status);
            console.log(error);
        } else {
            console.log(error);
        }
    }
};
