import { Navigate, Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/HomePage";
import { PrivateLayout } from "../layouts/PrivateLayout";

export const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<PrivateLayout />}>
                <Route path="/" element={<HomePage />} />
            </Route>
            <Route path="/*" element={<Navigate to={"/not-found"} replace />} />
        </Routes>
    );
};
