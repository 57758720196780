import { Outlet } from "react-router-dom";
import { Main, PageWrap } from "@vwfs-bronson/bronson-react";

export const Initializer = () => {
    return (
        <Main>
            <PageWrap>
                <Outlet />
            </PageWrap>
        </Main>
    );
};
