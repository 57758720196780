import { Notification } from "../utils/Notification";

export const types = {
    setRequestId: "Set request ID",
    notificationAdd: "[Notification] Add notification",
    notificationDelete: "[Notification] Delete notification",
};

interface Request {
    requestId: string;
}

export interface RequestState {
    request: Request;
}

export interface NotificationState {
    notifications: Notification[];
}

export interface NotificationAddAction {
    type: string;
    payload: Notification;
}

export interface NotificationDeleteAction {
    type: string;
    payload: string;
}

interface Error {
    value: string;
    code: string;
    internalValue: string;
}

export interface FSOVCoreInterface {
    header: {
        token: string;
        reference: string;
        returnUrl: string;
    };
    errors: Error[];
}

export type NotificationActionTypes = NotificationAddAction | NotificationDeleteAction;
