import lodash from "lodash";

export const SEVERITY_NOTIFICACION = {
    DEFAULT: "default",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
} as const;

export const TYPE_NOTIFICACION = {
    ALERT: "ALERT",
    TOAST: "TOAST",
    MODAL: "MODAL",
} as const;

type SeverityNotificacion = (typeof SEVERITY_NOTIFICACION)[keyof typeof SEVERITY_NOTIFICACION];
type TypeNotificacion = (typeof TYPE_NOTIFICACION)[keyof typeof TYPE_NOTIFICACION];

export interface Notification {
    Description: string;
    Id: string;
    Title: string;
    Severity: SeverityNotificacion;
    Type: TypeNotificacion;
    Timeout: number | null;
}

const createNotification = ({
    Description = "",
    Id = "",
    Title = "",
    Severity = SEVERITY_NOTIFICACION.WARNING,
    Type = TYPE_NOTIFICACION.ALERT,
    Timeout = null,
}: Partial<Notification>): Notification => {
    return {
        Description,
        Id: Id || lodash.uniqueId(),
        Title,
        Severity,
        Type,
        Timeout,
    };
};

const newAlert = (Description: string = ""): Notification => {
    return {
        Description,
        Id: lodash.uniqueId(),
        Title: "",
        Severity: SEVERITY_NOTIFICACION.WARNING,
        Type: TYPE_NOTIFICACION.ALERT,
        Timeout: 4000,
    };
};

export const createNotificationObject = (object: Notification): Notification => {
    if (typeof object === "object") {
        if (
            object.Description &&
            object.Id &&
            object.Title &&
            object.Severity &&
            object.Type &&
            object.Timeout !== undefined
        ) {
            return object;
        }
        return createNotification(object);
    }
    return newAlert(object);
};
