import { Route, Routes } from "react-router-dom";
import { Initializer } from "../layouts/Initializer";
import { NotFoundPage } from "../pages/NotFoundPage";
import { UnAuthorizePage } from "../pages/UnAuthorizePage";
import { PrivateRoutes } from "./PrivateRoutes";
import { AuthPage } from "../pages/AuthPage";

export const GeneralRoutes = () => {
    return (
        <Routes>
            <Route element={<Initializer />}>
                <Route path="/*" element={<PrivateRoutes />} />
                {/* <Route path="/#/auth/:token" element={<AuthPage />} /> */}
                <Route path="/auth/:token" element={<AuthPage />} />
                <Route path="/unauthorized" element={<UnAuthorizePage />} />
                <Route path="/not-found" element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};
