import { NotificationModal } from "./NotificationModal";
import { NotificationToast } from "./NotificationToast";

export const Notification = () => {
    return (
        <>
            <NotificationToast />
            <NotificationModal />
        </>
    );
};
