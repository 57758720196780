import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { ThemeSelector } from "./themes/ThemeSelector.tsx";
import { Provider } from "react-redux";
import { store } from "./store/store.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeSelector>
                <App />
            </ThemeSelector>
        </Provider>
    </React.StrictMode>
);
