import { HashRouter } from "react-router-dom";
import { AuthProvider } from "./contexts";
import { GeneralRoutes } from "./routes/GeneralRoutes";

function App() {
    return (
        <HashRouter>
            <AuthProvider>
                <GeneralRoutes></GeneralRoutes>
            </AuthProvider>
        </HashRouter>
    );
}

export default App;
