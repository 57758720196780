const TOKEN_KEY = "FSOV_SELECTOR";

export const LocalStorageService = {
    setItem(value: string) {
        localStorage.setItem(TOKEN_KEY, value);
    },
    getItem() {
        return localStorage.getItem(TOKEN_KEY);
    },
    removeItem() {
        localStorage.removeItem(TOKEN_KEY);
    },
};
